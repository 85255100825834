import { Constants } from "../constants/Constants";
import * as backendUserService from "../services/backendService";
import { authenticationGuard } from "@/auth/authenticationGuard";
import store from "../store/index";
import { fetchMembershipData } from "@timer2ticket/timer2ticket-client-vue-library";
import { getInstance } from "../auth/auth0-plugin";

export const withoutMembershipGuard = async (to, from, next) => {
    blockForNonCommercialVersion(to, from, next);
    return authenticationGuard(to, from, next, async (to, from, next) => {
        if (!await fetchUserDataGuard()) {
            return next({ name: "Fallback" });
        }

        if (store.state.membership.name) {
            return next({ name: "Home" });
        }

        return next();
    });
}

export const connectionsGuard = async (to, from, next) => {
    await authenticateAndFetchDataGuard(to, from, next);
}

export const logsGuard = async (to, from, next) => {
    await authenticateAndFetchDataGuard(to, from, next);
}

export const logsJobLogsGuard = async (to, from, next) => {
    await authenticateAndFetchDataGuard(to, from, next);
}

export const logsSyncLogsGuard = async (to, from, next) => {
    blockForNonCommercialVersion(to, from, next);
    await authenticateAndFetchDataGuard(to, from, next);
}

export const profileGuard = async (to, from, next) => {
    await authenticateAndFetchDataGuard(to, from, next);
}

export const profileSettingsGuard = async (to, from, next) => {
    await authenticateAndFetchDataGuard(to, from, next);
}

export const helpGuard = async (to, from, next) => {
    await authenticateAndFetchDataGuard(to, from, next);
}

export const profileMembershipGuard = async (to, from, next) => {
    blockForNonCommercialVersion(to, from, next);
    await authenticateAndFetchDataGuard(to, from, next);
}

export const profileBillingGuard = async (to, from, next) => {
    blockForNonCommercialVersion(to, from, next);
    await authenticateAndFetchDataGuard(to, from, next);
}

export const homeGuard = async (to, from, next) => {
    await authenticateAndFetchDataGuard(to, from, next);
}

export const fallbackGuard = async (to, from, next) => {
    return next();
}

const authenticateAndFetchDataGuard = async (to, from, next) => {
    authenticationGuard(to, from, next, async (to, from, next) => {
        if (!await fetchUserDataGuard()) {
            return next({ name: "Fallback" });
        }

        if (Constants.IS_COMMERCIAL_VERSION && !store.state.membership.name) {
            return next({ name: "Without-Membership" });
        }

        return next();
    });
}

const fetchUserDataGuard = async () => {
    // check if data is already fetched
    if (!store.state.isUserDataFetched) {
        // get user data
        const userData = await backendUserService.fetchUserData();

        if (!userData) {
            console.error("Unable to fetch user data.")
            return false;
        }

        // store user data to vuex store
        await store.dispatch("fetchUserData", userData);

        const authService = getInstance();
        await store.dispatch("changeUserAuth0Id", authService.user.sub);

        if (Constants.IS_COMMERCIAL_VERSION) {
            // get membership data
            const membershipData = await fetchMembershipData();

            if (!membershipData) {
                console.error("Unable to fetch membership data.");
                return false;
            }

            // store membership data to vuex store
            await store.dispatch("fetchMembershipData", membershipData);
        }
    }
    return true;
}

const blockForNonCommercialVersion = (to, from, next) => {
    if (!Constants.IS_COMMERCIAL_VERSION) {
        return next({ name: "Fallback" });
    }
}
