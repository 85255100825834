const ToolType = {
    REDMINE: {
        name: "Redmine",
        type: "Project",
    },
    TOGGL_TRACK: {
        name: "Toggl Track",
        type: "Time",
    },
    JIRA: {
        name: "Jira",
        type: "Project",
    },
};

export {ToolType};
