import { getInstance } from "../auth/auth0-plugin";
import axios from "axios";
import { Constants } from "@/constants/Constants";

/**
 * Fetches user data from the backend.
 * @returns {Promise<*>}
 */
export const fetchUserData = async () => {
    let MAX_RETRIES = 5;

    const authService = getInstance();

    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = null;
    while (!responseData && MAX_RETRIES-- > 0) {
        await axios.get(`${Constants.BACKEND_API_URL}users/${authService.user.sub}`, data)
            .then(response => {
                if (response.status === 200) {
                    responseData = response.data;
                }
            }).catch(error => {
                console.error("Error fetching user data.");
            });
        if (!responseData || MAX_RETRIES > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
    return responseData;
}

export const fetchUserConnections = async () => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = null;
    await axios.get(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/connections`, data)
        .then((response) => {
            responseData = response.data;
        })
        .catch((error) => {
            console.error("Error fetching user connections.");
        });
    return responseData;
}

export const createConnection = async (connection) => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = null;
    await axios.post(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/connections`, connection, data)
        .then((response) => {
            responseData = response.data;
        })
        .catch((error) => {
            console.error("Error creating connection.");
        });
    return responseData;
}

export const updateConnection = async (connection) => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = null;
    await axios.put(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/connections/${connection.databaseId}`, connection, data)
        .then((response) => {
            responseData = response.data;
        })
        .catch((error) => {
            console.error("Error updating connection.");
        });
    return responseData;
}

export const patchConnection = async (connectionDatabaseId, body) => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = null;
    await axios.patch(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/connections/${connectionDatabaseId}`, body, data)
        .then((response) => {
            responseData = response.data;
        })
        .catch((error) => {
            console.error("Error patching connection.");
        });
    return responseData;
}

export const syncConfigObjects = async (connectionDatabaseId) => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = false;
    await axios.post(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/connections/${connectionDatabaseId}/syncConfigObjects`, null, data)
        .then((response) => {
            responseData = true;
        })
        .catch((error) => {
            console.error("Error starting sync of config objects.");
        });
    return responseData;
}


export const syncTimeEntries = async (connectionDatabaseId) => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = false;
    await axios.post(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/connections/${connectionDatabaseId}/syncTimeEntries`, null, data)
        .then((response) => {
            responseData = true;
        })
        .catch((error) => {
            console.error("Error starting sync of config objects.");
        });
    return responseData;
}


export const deleteConnection = async (connectionDatabaseId) => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = false;
    await axios.delete(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/connections/${connectionDatabaseId}`, data)
        .then((response) => {
            responseData = true;
        })
        .catch((error) => {
            console.error("Error starting sync of config objects.");
        });
    return responseData;
}


export const restoreConnection = async (connectionDatabaseId) => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = false;
    await axios.post(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/connections/${connectionDatabaseId}/restore`, null, data)
        .then((response) => {
            responseData = true;
        })
        .catch((error) => {
            console.error("Error starting sync of config objects.");
        });
    return responseData;
}

export const patchUser = async (body) => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = null;
    await axios.patch(`${Constants.BACKEND_API_URL}users/${authService.user.sub}`, body, data)
        .then((response) => {
            responseData = response.data;
        })
        .catch((error) => {
            console.error("Error patching user!");
        });
    return responseData;
}

export const changePassword = async () => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = null;
    await axios.post(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/changePassword`, null, data)
        .then((response) => {
            responseData = response.data;
        })
        .catch((error) => {
            console.error("Error changing password!");
        });
    return responseData;
}

export const deleteAccount = async () => {
    const authService = getInstance();
    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = false;
    await axios.delete(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/`, data)
        .then((response) => {
            responseData = true;
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        })
        .catch((error) => {
            console.error("Error changing password!");
        });
    return responseData;
}

export const getLogs = async () => {
    const authService = getInstance();

    const accessToken = await authService.getTokenSilently();
    const data = {
        'headers': {
            'Authorization': `Bearer ${accessToken}`,
        }
    };

    let responseData = null;
    await axios.get(`${Constants.BACKEND_API_URL}users/${authService.user.sub}/jobLogs`, data)
        .then(response => {
            if (response.status === 200) {
                responseData = response.data;
            }
        }).catch(error => {
            console.error("Error fetching user data.");
        });
    return responseData;
}
