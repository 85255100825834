import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // fetched flags
        isUserDataFetched: false,

        // user data
        user: {
            userAuth0Id: "",
            userMail: "",
            registratedDate: "",
            timeZone: "",
            notifications: {
                syncProblemsInfo: false,
            },
        },

        // membership data
        membership: {
            name: "",
            maxActiveConnections: 0,
            finishes: "",
            immediateSyncs: 0,
        },

        // user connection boxes
        userConnectionBoxes: [],
        usedUserConnectionBoxes: 0,
    },
    getters: {
        usedNumberOfActiveConnections(state) {
            let count = 0;
            for (let i = 0; i < state.userConnectionBoxes.length; ++i) {
                if (state.userConnectionBoxes[i].isActive === true) {
                    count++;
                }
            }
            return count;
        },
        canSwitchConnectionToActive(state, getters) {
            return getters.usedNumberOfActiveConnections < state.membership.maxActiveConnections;
        }
    },
    mutations: {
        // fetched flags
        changeIsUserDataFetched(state, isUserDataFetched) {
            state.isUserDataFetched = isUserDataFetched;
        },

        // user data
        changeUserAuth0Id(state, newUserAuth0Id) {
            state.user.userAuth0Id = newUserAuth0Id;
        },
        changeUserEmail(state, newUserEmail) {
            state.user.userMail = newUserEmail;
        },
        changeUserRegistratedDate(state, newUserRegistratedDate) {
            state.user.registratedDate = newUserRegistratedDate;
        },
        changeUserTimeZone(state, newUserTimeZone) {
            state.user.timeZone = newUserTimeZone;
        },
        changeUserNotificationsSyncProblemsInfo(state, newUserNotificationsSyncProblemsInfo) {
            state.user.notifications.syncProblemsInfo = newUserNotificationsSyncProblemsInfo;
        },

        // Membership data
        changeMembershipName(state, newMembershipName) {
            state.membership.name = newMembershipName;
        },
        changeMembershipMaxActiveConnections(state, newMembershipMaxActiveConnections) {
            state.membership.maxActiveConnections = newMembershipMaxActiveConnections;
        },
        changeMembershipFinishes(state, newMembershipFinishes) {
            state.membership.finishes = newMembershipFinishes;
        },
        changeMembershipImmediateSyncs(state, newMembershipImmediateSyncs) {
            state.membership.immediateSyncs = newMembershipImmediateSyncs;
        },

        // user connection boxes
        changeUserConnectionBoxes(state, newUserConnectionBoxes) {
            state.userConnectionBoxes = newUserConnectionBoxes;
        },
    },
    actions: {
        fetchUserData(context, userData) {
            context.commit("changeIsUserDataFetched", true);

            context.commit("changeUserEmail", userData.email);
            context.commit("changeUserRegistratedDate", userData.registratedDate);
            context.commit("changeUserTimeZone", userData.timeZone);
            context.commit("changeUserNotificationsSyncProblemsInfo", userData.notifiactionSettings.syncProblemsInfo);
        },

        fetchMembershipData(context, membershipData) {
            context.commit("changeMembershipName", membershipData.currentMembership);
            context.commit("changeMembershipMaxActiveConnections", membershipData.currentConnections);
            context.commit("changeMembershipFinishes", membershipData.currentMembershipFinishes);
            context.commit("changeMembershipImmediateSyncs", membershipData.currentImmediateSyncs);
        },

        changeUserEmail(context, newUserEmail) {
            context.commit("changeUserEmail", newUserEmail);
        },

        changeUserAuth0Id(context, newUserAuth0Id) {
            context.commit("changeUserAuth0Id", newUserAuth0Id);
        },

        changeUserTimeZone(context, newUserTimeZone) {
            context.commit("changeUserTimeZone", newUserTimeZone);
        },

        changeUserNotificationsSyncProblemsInfo(context, newUserNotificationsSyncProblemsInfo) {
            context.commit("changeUserNotificationsSyncProblemsInfo", newUserNotificationsSyncProblemsInfo);
        },

        changeUserConnectionBoxes(context, newUserConnectionBoxes) {
            context.commit("changeUserConnectionBoxes", newUserConnectionBoxes);
        },

        useImmediateSync(context) {
            context.commit("changeMembershipImmediateSyncs", context.state.membership.immediateSyncs - 1);
        }
    },
    modules: {},
});
