export class Constants {

    static BACKEND_API_URL = process.env.VUE_APP_BACKEND_URL || 'http://localhost:3001/api/';
    static IS_COMMERCIAL_VERSION = Constants.getBooleanValue(process.env.VUE_APP_IS_COMMERCIAL_VERSION);
    static POLLING_INTERVAL = process.env.VUE_APP_POLLING_INTERVAL || 5000; // in miliseconds

    static AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
    static AUTH0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID
    static AUTH0_AUDIENCE = process.env.VUE_APP_AUTH0_AUDIENCE

    static getBooleanValue(value) {
        if (typeof value === 'boolean') {
            return value;
        }

        if (typeof value === 'string') {
            return value === 'true';
        }

        return false;
    }
}
