import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Import the Auth0 plugin
import { Auth0Plugin } from "@/auth/auth0-plugin";

import { Constants } from "./constants/Constants";

Sentry.init({
    Vue,
    dsn: "https://0396f1735346b1e28f0a393a6245308a@o4506110897750016.ingest.sentry.io/4506388972437504",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const domain = Constants.AUTH0_DOMAIN;
const clientId = Constants.AUTH0_CLIENT_ID;
const audience = Constants.AUTH0_AUDIENCE;

// Install the authentication plugin
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    },
});

if (Constants.IS_COMMERCIAL_VERSION) {
    console.info("Using commercial version");
    import('@timer2ticket/timer2ticket-client-vue-library').then((module) => {
        Vue.use(module.default);
    });
}

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
