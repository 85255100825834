import Vue from "vue";
import VueRouter from "vue-router";
import {
  connectionsGuard, fallbackGuard, helpGuard, homeGuard,
  logsGuard, logsJobLogsGuard, logsSyncLogsGuard, profileBillingGuard,
  profileGuard, profileMembershipGuard,
  profileSettingsGuard,
  withoutMembershipGuard
} from "./navigationGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/withoutMembership",
    name: "Without-Membership",
    component: () =>
      import(
        /* webpackChunkName: "WithoutMembership" */ "../views/WithoutMembership.vue"
      ),
    beforeEnter: withoutMembershipGuard,
  },
  {
    path: "/connections",
    name: "Connections",
    component: () =>
      import(/* webpackChunkName: "Connections" */ "../views/Connections.vue"),
    beforeEnter: connectionsGuard,
  },
  {
    path: "/logs",
    name: "Logs",
    component: () => import(/* webpackChunkName: "Logs" */ "../views/Logs.vue"),
    beforeEnter: logsGuard,
    children: [
      {
        path: "/logs/jobLogs",
        name: "Logs-JobLogs",
        component: () =>
          import(
                /* webpackChunkName: "ProfileSettings" */ "@/views/LogsJobLogs"
          ),
        beforeEnter: logsJobLogsGuard,
      },
      {
        path: "/logs/syncLogs",
        name: "Logs-SyncLogs",
        component: () =>
          import(
                /* webpackChunkName: "ProfileSettings" */ "@/views/LogsSyncLogs"
          ),
        beforeEnter: logsSyncLogsGuard,
      },
    ]
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "@/views/Profile"),
    beforeEnter: profileGuard,
    children: [
      {
        path: "/profile/settings",
        name: "Profile-Settings",
        component: () =>
          import(
            /* webpackChunkName: "ProfileSettings" */ "@/views/ProfileSettings"
          ),
        beforeEnter: profileSettingsGuard,
      },
      {
        path: "/profile/membership",
        name: "Profile-Membership",
        component: () =>
          import(
            /* webpackChunkName: "ProfileMembership" */ "@/views/ProfileMembership"
          ),
        beforeEnter: profileMembershipGuard,
      },
      {
        path: "/profile/billing",
        name: "Profile-Billing",
        component: () =>
          import(
            /* webpackChunkName: "ProfileBilling" */ "@/views/ProfileBilling"
          ),
        beforeEnter: profileBillingGuard,
      },
    ],
  },
  {
    path: "/help",
    name: "Help",
    component: () =>
      import(
        /* webpackChunkName: "WithoutMembership" */ "../views/Help.vue"
      ),
    beforeEnter: helpGuard,
  },
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "Connections" */ "../views/Connections.vue"),
    beforeEnter: homeGuard,
  },
  {
    path: "*",
    name: "Fallback",
    component: () =>
      import(/* webpackChunkName: "Fallback" */ "../views/Fallback.vue"),
    beforeEnter: fallbackGuard,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
