import { ToolType } from "../../enums/tools/typeOfTool";

export function mapConnectionToConnectionBox(connection) {
    return getConnectionBox(
        connection._id,
        connection.userConnectionId,
        connection.isActive,
        getSyncJobDefinition(
            connection.configSyncJobDefinition.selectionOfDays,
            connection.configSyncJobDefinition.syncTime,
            connection.configSyncJobDefinition.everyHour,
            connection.configSyncJobDefinition.schedule,
            connection.configSyncJobDefinition.lastJobTime,
            connection.configSyncJobDefinition.status
        ),
        getSyncJobDefinition(
            connection.timeEntrySyncJobDefinition.selectionOfDays,
            connection.timeEntrySyncJobDefinition.syncTime,
            connection.timeEntrySyncJobDefinition.everyHour,
            connection.timeEntrySyncJobDefinition.schedule,
            connection.timeEntrySyncJobDefinition.lastJobTime,
            connection.timeEntrySyncJobDefinition.status

        ),
        getService(connection.firstService),
        getService(connection.secondService),
        getProjectMappings(connection.projectMappings)
    );
}

export function getConnectionBox(dbId, userBoxId, isActive, confObjects, timeEntries, firstService = {}, secondService = {}, projectMappings = []) {
    return {
        databaseId: dbId,
        connectionBoxId: userBoxId,
        isActive: isActive,
        schedule: {
            configObjects: confObjects,
            timeEntries: timeEntries,
        },
        firstTool: firstService,
        secondTool: secondService,
        projectPairs: projectMappings
    }
}

export function getSyncJobDefinition(selectionOfDays, syncTime, everyHour, schedule = null, lastJobTime = null, status = null) {
    return {
        selectionOfDays: selectionOfDays,
        syncTime: syncTime,
        everyHour: everyHour,
        schedule: schedule,
        lastJobTime: lastJobTime,
        status: status,
    };
}

export function getService(service) {
    if (service.name === ToolType.REDMINE.name) {
        return {
            tool: service.name,
            redmineApiKey: service.config.apiKey,
            redmineApiPoint: service.config.apiPoint,
            userId: service.config.userId,
            selectedRedmineDefaultTimeEntryActivity: service.config.defaultTimeEntryActivity.id,
            selectedRedmineDefaultTimeEntryActivityName: service.config.defaultTimeEntryActivity.name,
            customField: service.config.customField
        }
    } else if (service.name === ToolType.TOGGL_TRACK.name) {
        return {
            tool: service.name,
            togglTrackApiKey: service.config.apiKey,
            userId: service.config.userId,
            selectedTogglTrackWorkspace: service.config.workspace.id,
            selectedTogglTrackWorkspaceName: service.config.workspace.name,
        }
    } else if (service.name === ToolType.JIRA.name) {
        return {
            tool: service.name,
            jiraApiKey: service.config.apiKey,
            jiraDomain: service.config.domain,
            jiraUserEmail: service.config.userEmail,
            userId: service.config.userId,
            jiraFallbackIssue: service.config.fallbackIssue.fallbackIssue,
            jiraFallbackIssueName: service.config.fallbackIssue.name,
            jiraIgnoredIssueStates: service.config.ignoredIssueStates,
            customField: service.config.customField
        }
    } else {
        return {}
    }
}

export function getProjectMappings(projectMappings) {
    if (projectMappings.length === 0)
        return []
    else {
        const pairs = []
        projectMappings.forEach(p => {
            pairs.push({
                firstProject: {
                    id: p.idFirstService
                },
                secondProject: {
                    id: p.idSecondService
                },
                id: `${p.idFirstService}_${p.idSecondService}`
            })
        })
        return pairs
    }
}

export function getTimeFormatOptions(timeZoneName) {
    return {
        weekday: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
        timeZone: timeZoneName,
    };
}

export function getDateFormatOptions(timeZoneName) {
    return {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };
}

export class timeFormatOptions {
}