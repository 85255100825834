<template>
  <div v-if="$auth.isLoading" />
  <v-app v-else>
    <v-navigation-drawer v-model="drawer" app>
      <router-link :to="{ name: 'Connections' }">
        <v-img
          :src="require('@/assets/logo-t2t.png')"
          width="200"
          height="65"
          contain
          class="ml-5"
        />
      </router-link>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    items: [
      {
        title: "paths.connections",
        icon: "mdi-transit-connection-variant",
        to: { name: "Connections" },
      },
      {
        title: "paths.logs",
        icon: "mdi-table-large",
        to: { name: "Logs-JobLogs" },
      },
      {
        title: "paths.profile",
        icon: "mdi-account-circle",
        to: { name: "Profile-Settings" },
      },
      {
        title: "paths.help",
        icon: "mdi-help-circle",
        to: { name: "Help" },
      },
    ],
  }),
};
</script>
